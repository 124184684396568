.App {
  text-align: center;
  overflow-x: hidden !important;
 
}

.scroll-container {
  position: sticky !important;
  top: 0;
  left: 0;
  z-index: 99999;
  width: 100%;
}

.scroll-indicator {
  height: 2.8px;
  background-color: #e8ff2a;
  position: relative;
}
.remaining-scroll {
  height: 100%;
  background-color: white;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 999; 
}

@media (max-width: 767px) {
  .App {
    overflow-x: hidden !important;
    overflow: hidden !important;
  }
}
