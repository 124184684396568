@import url("https://fonts.googleapis.com/css2?family=Mukta:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mukta:wght@700&family=Playfair+Display:ital,wght@1,600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&family=Playfair+Display:ital,wght@1,600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Grape+Nuts&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=PT+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap");

/* import {banner_bg} from '../../' ; */

:root {
  --font-name: "Ubuntu", sans-serif;
}

#name {
  /* font-family: var(--font-name); */
  background: linear-gradient(
    287deg,
    rgba(66, 148, 246, 1) 6%,
    rgba(32, 105, 171, 1) 50%,
    rgba(35, 255, 245, 1) 99%
  );
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

html {
  /* Previous */

  /* --section-background-color: linear-gradient(
    to bottom left,
    rgba(17, 16, 16, 0.582),
    rgba(12, 8, 24, 0.904)
  );

  --image-gradient: linear-gradient(
    to bottom left,
    rgba(17, 16, 16, 0.678),
    rgba(12, 10, 22, 0.863)
  ); */

  /* Current */
  --section-background-color: linear-gradient(
    to bottom left,
    rgba(72, 74, 82, 0.582),
    rgba(49, 36, 45, 0.904)
  );

  --image-gradient: linear-gradient(
    to bottom left,
    rgba(36, 37, 42, 0.582),
    rgba(12, 14, 43, 0.956)
  );

  --imp-text-color: #cc005f;
}

.purple {
  color: #f4511e !important;
}

button:focus {
  box-shadow: none !important;
}
button {
  filter: drop-shadow(10px 10px 4px rgb(187, 222, 251)) !important;
}

/* --------- */
/*  Preloader */
/* --------- */
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: #0a0a0a;
  background-image: url(./assets/pre.svg);
  background-repeat: no-repeat;
  background-position: center;
}
#preloader1 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: #0a0a0a;
  background-image: url(./assets/pre7.svg);

  background-repeat: no-repeat;
  background-position: center;
}

#preloader-none {
  opacity: 0;
}

#no-scroll {
  overflow: hidden;
  height: 100vh;
}

/******** ScrollBar  Modification ******/
::-webkit-scrollbar {
  width: 7px;
  display: none !important;
}
/* track */
::-webkit-scrollbar-track {
  background-color: white;
}
/* handler */
::-webkit-scrollbar-thumb {
  /* background-color: rgba(219, 69, 219, 0.8); */
  background-color: #7c4dff;
  /* background: linear-gradient( purple, blue, cyan); */

  border-radius: 12px;
}
::-webkit-scrollbar-thumb:hover {
  /* background-color: rgba(29, 17, 138, 0.6); */
  background-color: red;
  border-radius: 12px;
}
@media (max-width: 767px) {
  ::-webkit-scrollbar {
    display: none !important;
  }
}

/******** ScrollBar  Modification ENDS ******/

/* --------- */
/* Navbar Section  */
/* --------- */

.sticky {
  background-color: #1b1a2ea9 !important;
  transition: all 0.3s ease-out 0s !important;
  box-shadow: 0px 10px 10px 0px rgba(9, 5, 29, 0.171) !important;
  backdrop-filter: blur(15px) !important;
}

.navbar {
  position: fixed !important;
  transition: all 0.3s ease-out 0s !important;

  /* transition: background-color 0.3s ease; */
  padding: 0.3rem 2rem !important;
  font-size: 1.2rem !important;
}

.active {
  border-bottom: 5px solid white;
}
.navbar-toggler {
  position: relative !important;
  background-color: transparent !important;
  border-color: transparent !important;
}

.navbar-toggler span {
  display: block !important;
  background-color: #be50f4 !important;
  height: 40px !important;
  width: 27px !important;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  transform: rotate(0deg) !important;
  left: 0 !important;
  opacity: 1 !important;
  margin: auto;
}

.navbar-toggler:focus,
.navbar-toggler:active {
  outline: 0 !important;
}

.navbar-toggler span:nth-child(1),
.navbar-toggler span:nth-child(3) {
  transition: transform 0.35s ease-in-out !important;
  transition: transform 0.35s ease-in-out !important;
}

.d-flex span:nth-child(1) {
  color: #2979ff;
  /* font-family: "Dancing Script", cursive; */
  font-family: "Grape Nuts", cursive;
  font-size: 30px;
  font-weight: 900;
  margin-left: -100px;
}
.navbar-toggler:not(.collapsed) span:nth-child(1) {
  position: absolute !important;
  left: 12px !important;
  top: 10px !important;
  transform: rotate(135deg) !important;
  opacity: 0.9 !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(2) {
  height: 12px !important;
  visibility: hidden !important;
  background-color: transparent !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(3) {
  position: absolute !important;
  left: 12px !important;
  top: 10px !important;
  transform: rotate(-135deg) !important;
  opacity: 0.9 !important;
}

@media (max-width: 767px) {
  .navbar {
    background-color: #181a27 !important;
  }
  .navbar-nav .nav-item a::after {
    display: none !important;
  }
  .d-flex span:nth-child(1) {
    margin-left: -40px;
    color: #2979ff;
    font-family: "Dancing Script", cursive;
    font-size: 30px;
    font-weight: 900;
  }
  .navbar-toggler span {
    background-color: #2979ff !important;
    height: 4px !important;
    width: 27px !important;
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    transform: rotate(0deg) !important;
    left: 0 !important;
    opacity: 1 !important;
    margin-right: -30px;
  }
}
.navbar-brand {
  color: rgb(250, 250, 250) !important;
}

.logo {
  height: 1.4em !important;
  width: 2.5em !important;
}

.navbar-nav .nav-link {
  color: white !important;
  padding-right: 1rem !important;
  /* padding-left: 1rem !important; */
}

.nav-link {
  padding: 0.8rem 1rem !important;
}

@media (max-width: 767px) {
  .nav-link {
    padding: 0.7rem 1rem !important;
  }
  .active {
    border-bottom: none;
    border-left: 5px solid #2979ff;
  }
}

.navbar-nav .nav-item {
  position: relative;
  margin-left: 20px;
}

.navbar-nav .nav-item a {
  font-weight: 400;
  transition: all 0.3s ease-out 0s;
  position: relative;
  z-index: 1;
}

.navbar-nav .nav-item a::after {
  content: "";
  position: relative;
  display: block;
  height: 5px;
  width: 0;
  border-radius: 16px;
  /* background: #c95bf5; */
  bottom: 1px;
  left: 0;
  z-index: -1;
  transition: all 0.3s ease-out 0s;
}

.navbar-nav .nav-item a:hover::after {
  width: 100%;
}

/************ Banner Css ************/
.banner {
  margin-top: 0;
  padding: 170px 0 50px 0;
  box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
}

.banner h1 {
  font-size: 65px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1;
  margin-bottom: 20px;
  display: block;
}
.banner p {
  color: #b8b8b8;
  font-size: 19px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  width: 96%;
}
.banner button {
  color: #fff;
  font-weight: 700;
  font-size: 20px;
  margin-top: 60px;
  letter-spacing: 0.8px;
  display: flex;
  align-items: center;
}
.banner button svg {
  font-size: 25px;
  margin-left: 10px;
  transition: 0.3s ease-in-out;
  line-height: 1;
}
.banner button:hover svg {
  margin-left: 25px;
}
.banner img {
  animation: moving 3s linear infinite;
  width: 500px;
}
@keyframes moving {
  0% {
    transform: translateY(-20px);
  }
  50% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(-20px);
  }
}
.txt-rotate > .wrap {
  border-right: 0.08em solid #ff5722;
}
.wrap {
  color: red;
}
.tagline {
  font-weight: 700;
  font-family: Arial, Helvetica, sans-serif;
  letter-spacing: 0.8px;
  padding: 8px 10px;
  /* initial */
  /* background: linear-gradient(
    90.21deg,
    rgb(132, 51, 219) -5.91%,
    rgb(180, 64, 226) 111.58%
  ); */
  background: linear-gradient(
    90.21deg,
    rgb(143, 67, 225) -5.91%,
    rgb(68, 28, 84) 111.58%
  );

  border: 2px solid rgba(255, 255, 255, 0.5);
  font-size: 20px;
  margin-bottom: 16px;
  display: inline-block;
  /* margin-top: 25px; */
}
#home img {
  animation: moving 3s linear infinite;
  width: 500px;
}
.bannerBox {
  /* border: 1px solid white; */
  height: 250px;
  margin-bottom: 80px;
}

.introFirst {
  margin-top: 15px;
  width: 1110px;
  text-align: justify;
  /* border: 1px solid white; */
}
.ResumeHome Button {
  box-shadow: rgb(255, 255, 255) 0px 1px 4px, rgb(51, 51, 51) 0px 0px 0px 3px;
}
@media (max-width: 767px) {
  .introFirst {
    margin-top: 35px;
    width: 100%;
    text-align: left;
    padding-bottom: 50px;
  }
  .tagline {
    margin-top: -80px !important;
    margin-left: 25px;
    margin-bottom: 30px;
  }
  .rocketwave img {
    display: none;
    /* margin-bottom: 15px; */
  }
  .animatedImage {
    display: none;
  }
  .bannerBox {
    /* border: 1px solid white; */
    margin-top: 10px;
    margin-bottom: 60px;
  }
  .ResumeHome {
    padding-bottom: 50px;
  }

  .ResumeHome Button {
    margin: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

/* text glitch  */

/* .text {
  margin-top: 1em;
} */

.content {
  /* position: relative; */
  /* display: inline-block;  */
  /* width: 100%; */
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.content .text {
  white-space: nowrap;
  /* color: #fff; */
  color: #ffc93c;
  font-weight: 700;
  text-transform: uppercase;
  transform: scale(1.45);
  letter-spacing: 2px;
  padding: 30px;
  pointer-events: none;
  font-family: var(--font-name);
}

.content .text::before,
.content .text::after {
  position: absolute;
  content: attr(data-text);
  padding: 30px;
  width: 100%;
  height: 100%;
  top: 0;
  background: transparent;
  /* color: #d81313; */
  /* color: hwb(213 11% 4%); */
  overflow: hidden;
  pointer-events: none;
  font-family: var(--font-name);
}

.content .text::before {
  left: -4px;
  /* text-shadow: -1px 0 rgb(255, 213, 0); */
  /* animation: glitch-1 2s 0s linear reverse infinite; */
}

.content .text::after {
  left: 4px;
  /* text-shadow: -2px 0 blue; */
  animation: glitch-2 4s 2s linear reverse infinite;
}

/* Description text */
.content + p {
  font-size: 19px;
  letter-spacing: 2px;
}

/* text glitch  */

/************ Banner ENDS ************/

/* --------- */
/* Home section */
/* --------- */
.wave {
  animation-name: wave-animation;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  transform-origin: 90% 90%;
  display: inline-block;
  animation-timing-function: ease-in-out;
}

@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(14deg);
  } /* The following five values can be played with to make the waving more or less extreme */
  20% {
    transform: rotate(-8deg);
  }
  30% {
    transform: rotate(14deg);
  }
  40% {
    transform: rotate(-4deg);
  }
  50% {
    transform: rotate(10deg);
  }
  60% {
    transform: rotate(0deg);
  } /* Reset for the last half to pause */
  100% {
    transform: rotate(0deg);
  }
}

/*  wave 1 */
/* .wave1 {
  animation-name: bounce;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  transform-origin: 90% 90%;
  display: inline-block;
  animation-timing-function: ease-in-out;
}

@keyframes bounce {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(14deg);
  } 
  20% {
    transform: rotate(-8deg);
  }
  30% {
    transform: rotate(14deg);
  }
  40% {
    transform: rotate(-4deg);
  }
  50% {
    transform: rotate(10deg);
  }
  60% {
    transform: rotate(0deg);
  } 
  100% {
    transform: rotate(0deg);
  }
} */

#tsparticles {
  position: fixed !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  width: 100%;
  height: 100%;
}

/* .home-header {
  padding-top: 30px !important;
} */

.home-section {
  z-index: -1;
  background-position: center;
  background-repeat: repeat;
  padding-bottom: 30px !important;
  padding-top: 30px !important;
  padding: 30px !important;
}

.home-content {
  padding: 7rem 0 2rem !important;
  color: whitesmoke;
  text-align: left;
}

.heading {
  font-size: 2.4em !important;
  /* padding-left: 50px !important; */
}

.heading-name {
  font-size: 2.5em !important;
  /* padding-left: 45px !important; */
}

.main-name {
  /* color: #cc005f; */
  color: #f4511e;
}

.Typewriter_wrapper {
  font-size: 2.2em !important;
  /* color: #cc005f !important; */
  color: #f4511e !important;
  font-weight: 600 !important;
}
.Typewriter__cursor {
  font-size: 2.4em !important;
  color: #f4511e !important;
}
.colDescription {
  width: 60%;
}
.phone__intro {
  display: none;
}
@media (max-width: 767px) {
  .heading-name {
    font-size: 1.5em !important;
    display: flex;
    flex-direction: column;
    /* padding-left: 45px !important; */
  }

  #home img {
    /* animation: moving 3s linear infinite; */
    animation: none;
    width: 350px;
  }
  .Typewriter__cursor {
    display: none !important;
  }
  .typePad {
    font-size: 5px;
  }
  .Typewriter_wrapper {
    display: none;
  }
  .phone__intro {
    /* font-family: 'Courier New', Courier, monospace;
     */
    margin-top: 20px;
    /* font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
      "Lucida Sans Unicode", Geneva, Verdana, sans-serif; */
    font-family: "Dancing Script", cursive;
    text-align: center;
    /* color: #fdd835; */
    color: #e0e0e0;
    font-weight: 900;
    font-size: 28px;
    display: block;
  }
  #resume-button-1 {
    position: relative !important;
    /* z-index: 10; */
  }
  #resume-link-1 {
    z-index: 10;
  }
}

.myAvtar {
  /* justify-content: center !important; */
  padding-top: 8em !important;
  height: 450px;
  /* border: 5px solid yellow; */
  width: 280px;
}
.myAvtar img {
  width: 280px;
}

@media (max-width: 767px) {
  .myAvtar {
    margin-top: -100px !important;
    margin-bottom: -80px !important;
  }
  .myAvtar img {
    width: 320px !important;
  }
}

.home-about-section {
  /* position: relative; */
  /* background-image: var(--image-gradient),
    url(./assets/bg7.jpg); */
  /* background-repeat: no-repeat !important; */
  background-position: center;

  padding-bottom: 10px !important;
  padding: 50px !important;
  padding-top: 150px !important;
}

.home-about-description {
  color: white !important;
  padding-top: 40px !important;
  padding-bottom: 20px !important;
  text-align: center;
  /* border: 2px solid red; */
  /* margin-right: 55px; */
}

.home-about-body {
  /* margin-top: 90px; */
  /* padding: 40px; */
  font-size: 1.2em !important;
  /* text-align: left; */
  /* border: 1px solid white; */
}
.home-about-body p {
  padding-right: 50px;
  text-align: justify;
  margin-top: 15px;
  /* line-height: 2.5rem; */
}

.home-about-body1 {
  color: white !important;
  padding-top: 40px !important;
  padding-bottom: 40px !important;
  text-align: center;
  text-align: justify;
  margin-top: 15px;
  font-size: 1.2em !important;
}

.home-about-body1 p {
  text-align: justify;
  margin-top: 15px;
  /* line-height: 2.5rem; */
}

.home-about-social {
  text-align: center !important;
  padding-top: 150px;
  padding-bottom: 40px;
  color: white !important;
  /* border: 2px solid yellow; */
}

.home-about-social-links {
  justify-content: center !important;
  /* padding-top: 15px !important; */
  display: inline-block !important;
  position: relative !important;
  padding-inline-start: 0 !important;
}

.home-social-icons {
  position: relative !important;
  display: inline-block !important;
  width: 40px !important;
  height: 40px !important;
  text-align: center !important;
  font-size: 1.2em !important;
  line-height: 2em !important;
  background: rgba(255, 255, 255, 0.972) !important;
  border-radius: 50% !important;
  transition: 0.5s !important;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px;
}

.home-social-icons::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: #68187a;
  transition: 0.5s;
  transform: scale(0.9);
  z-index: -1;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px;
}

.home-social-icons:hover::before {
  transform: scale(1.1);
  /* box-shadow: 0 0 15px #801f95; */
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px;
}

.home-social-icons:hover {
  color: #87209e;
  /* box-shadow: 0 0 5px #18ffff; */
  box-shadow: rgb(255, 235, 59) 0px 12px 30px 4px;
  text-shadow: 0 0 2px #18ffff;
}

.social-icons {
  display: inline-block !important;
  padding-right: 15px;
  padding-left: 15px;
  z-index: 999999;
  /* height: 40px;
  width: 40px;
  border-radius: 50%;
  overflow: hidden;
  padding: 4px;
  border: 1px dashed #fcfcfc;
  animation: clockwiseSpin 5s linear infinite; */
}

/* .social-icons:nth-child(3) img {
  width: 100%;
  height: 100%;
} */

.icon-colour {
  color: #212121 !important;
}
/* About  */

#contact-email {
  font-size: 25px;
  margin-top: 15px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  /* border: 2px solid white; */
}
#contact-phone {
  padding: 40px;
  text-align: center;
}
.summary {
  /* padding: 15px; */
  text-align: justify;
  margin-top: 45px;
}

@media (max-width: 767px) {
  #contact-email {
    font-size: 18px;
    margin-top: 15px;
    /* border: 1px solid white; */
  }
  #contact-phone {
    text-align: justify;
  }
  .home-about-body {
    margin-top: 50px;
    /* border: 1px solid white; */
  }

  .home-about-description {
    text-align: justify;
  }

  .home-about-body p {
    text-align: center;
    margin-top: 15px;
  }

  .home-about-body1 {
    padding: 30px !important;
    /* border: 1px solid white; */
    margin-top: 30px;
  }
  .home-about-body1 p {
    /* padding: 15px !important; */
    /* border: 1px solid white; */
    text-align: center;
    margin-top: 15px;
  }
}

.home-about-section h1:nth-child(1) {
  color: white;
  /* font-size: 50px; */
  font-weight: 700;
}
.home-about-section h1:nth-child(2) {
  color: white;
  font-size: 2.5rem;
  /* margin-top: 35px; */
}
.topBtn {
  position: fixed;
  bottom: 2rem;
  right: 1.8rem;
}

.insidetopBtn {
  font-size: 40px;
  border-radius: 100%;
  background-color: white;
  border: none;
}

/* Top Button  */
@media (max-width: 767px) {
  .topBtn {
    position: fixed;
    bottom: 2.5rem;
    right: 1rem;
  }
  .insidetopBtn {
    font-size: 30px;
    border-radius: 100%;
    background-color: white;
    border: none;
  }
  .home-about-section {
    /* z-index: -1; */
    padding: 0 !important;
    padding-top: 150px !important;
  }
  .home-about-section h1:nth-child(1) {
    color: white;
    /* font-size: 90px !important; */
    /* padding-top: 100px !important; */
    font-weight: 700;
  }
  .home-about-section h1:nth-child(2) {
    color: white;
    font-size: 2rem;
    padding-top: 35px;
  }
}

/* --------- */
/* Footer */
/* --------- */
.footer {
  background-color: rgb(10, 4, 22);
  bottom: 0 !important;
  padding-top: 10px !important;
  /* padding-bottom: 8px !important ; */
}
.footer-copywright {
  text-align: center !important;
}

.footer-body {
  z-index: 1;
  text-align: center !important;
}

@media (max-width: 767px) {
  .footer-copywright {
    text-align: center !important;
  }

  .footer-body {
    text-align: center !important;
  }
}

.footer h3 {
  font-size: 1em;
  color: white !important;
  margin-top: 0.5em !important;
  margin-bottom: 0.5em !important;
}
.footer-icons {
  margin-top: 0.5em !important;
  margin-bottom: 0.5em !important;
  padding: 0 !important;
}

.blockquote-footer {
  color: #a588c0 !important;
}
/* --------- */
/* Projects */
/* --------- */
.project-section {
  position: relative !important;
  padding-top: 150px !important;
  padding-bottom: 30px !important;
  background-image: var(--section-background-color) !important;
}
.project-section1 {
  position: relative !important;
  padding-top: 150px !important;
  padding-bottom: 30px !important;
  padding-left: 50px;
  padding-right: 50px !important;
  background-repeat: no-repeat;
  /* background-position:  ; */
  /* background-image: var(--image-gradient), url("./assets/bg4.jpg") !important; */
}

/*  background-image: var(--image-gradient), url(./assets/img/banner-bg.png);
 position: relative;
  z-index: -1;
  background-position: top center;
  background-repeat: no-repeat;
  padding-bottom: 30px !important;
  padding-top: 30px !important;
  padding: 30px !important;

*/

.project-card {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
  padding-left: 25px !important;
  padding-right: 25px !important;
  height: auto !important;
}

.project-card-view {
  /* box-shadow: 0 4px 5px 3px rgba(119, 53, 136, 0.459) !important; */
  box-shadow: 0 4px 5px 3px white !important;
  color: #cccccc !important;
  background-color: transparent !important;
  /* opacity: 0.9 !important; */
  transition: all 0.5s ease 0s !important;
  height: 100% !important;
}
.project-card-view1 {
  color: #cccccc !important;
  background-color: transparent !important;
  outline: none !important;
  height: 100% !important;
  border: none !important;
  margin-bottom: 120px !important;
}

.iconToast {
  color: #ef6c00 !important;
}
.foo {
  text-transform: uppercase;
  color: red !important;
  font-weight: 900;
}
.fontToast {
  font-family: "Source Code Pro", monospace !important;
}
.project-card-view:hover {
  transform: scale(1.06) !important;
  overflow: hidden !important;
  /* box-shadow: 0 4px 4px 5px rgba(129, 72, 144, 0.561) !important;  */
  /* box-shadow: 0 4px 4px 5px #ff6f00 !important;  */
  box-shadow: 0 4px 4px 5px #000000 !important;
}

.gridContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.project-description {
  text-align: justify;
}
.project-img {
  width: 110% !important;
  /* filter: drop-shadow(15px 5px 4px #4444dd); */
  /* filter: drop-shadow(15px 5px 4px rgb(255, 213, 79)); */
  filter: drop-shadow(10px 5px 5px rgb(187, 222, 251));
  /* filter: drop-shadow(0 -1mm 2mm rgb(255, 213, 79)); */
}
.project-img1 {
  width: 115% !important;
  /* filter: drop-shadow(15px 5px 4px #4444dd); */
  filter: drop-shadow(0 1rem 0.6rem #4444dd);
  margin-bottom: 50px !important;
}

.project-body {
  padding: 25px !important;
  /* padding-left: 10px !important; */
  margin-left: 30px !important;
  /* border: 1px solid yellow; */
}

.project-title {
  font-family: "Dancing Script", cursive;
  font-size: 20px !important;
  /* font-weight: 800; */
  text-transform: uppercase;
  color: #ffa000;
}

.link-style {
  text-decoration: none !important;
}
.btnSingle {
  /* background-color: yellow !important; */
  filter: drop-shadow(0 0 0.75rem rgb(187, 222, 251));
  /* filter: drop-shadow(0 6mm 2mm red); */
}
.btnSingle:hover {
  background-color: yellow;
}

@media (max-width: 767px) {
  .gridContainer {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
  .mobileBtn {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .project-github-link {
    margin-bottom: 10px;
    width: 200px !important;
  }
  .project-description {
    margin-top: 20px;
    text-align: justify;
  }
  .project-img {
    width: 100% !important;
  }
  .project-img1 {
    margin-bottom: none !important;
  }
  .project-body {
    padding: 0px !important;
    margin-left: 0px !important;
  }
  .project-section1 {
    position: relative !important;
    padding-top: 150px !important;
    /* padding-bottom: 30px !important; */
    /* padding-left: 50px; */
    /* padding-right: 50px !important; */
    /* background-repeat: no-repeat; */
  }
}

.blog-card {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
  padding-left: 25px !important;
  padding-right: 25px !important;
  height: auto !important;
}

.blog-card-view {
  background-color: transparent !important;
  box-shadow: 0 3px 3px 2px rgba(145, 77, 161, 0.459) !important;
  color: white !important;
  transition: all 0.5s ease 0s !important;
  height: 100% !important;
}

.blog-link {
  color: white !important;
  text-decoration: none !important;
}

.blog-link:hover {
  cursor: pointer !important;
}

.blog-card-view:hover {
  transform: scale(1.02) !important;
  overflow: hidden !important;
  box-shadow: 0 3px 3px 5px rgba(155, 88, 173, 0.65) !important;
}

.card-img-top {
  padding: 20px !important;
  /* opacity: 0.8 !important; */
  border-radius: 10px !important;
}

.blog-img {
  padding: 0px !important;
  opacity: 0.8 !important;
  border-radius: 0px !important;
}

.btn-primary {
  color: #fff !important;
  background-color: #623686 !important;
  border-color: #623686 !important;
}

.btn-primary:hover {
  color: #fff !important;
  background-color: #6d20c5d7 !important;
  border-color: #6d20c5d7 !important;
}
.btn:focus {
  outline: none !important;
  box-shadow: none !important;
}
.project-heading {
  color: white !important;
  font-size: 3em !important;
  font-weight: 500 !important;
  padding-top: -300px !important;
}
.ProjectCount {
  color: white;
  width: 400px;
  box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
  margin-top: 40px !important;
  border-radius: 6px;
  padding: 6px;
}

#stats a {
  margin-right: 20px;
}
#github-top-langs {
  margin-top: 15px;
}

@media (max-width: 767px) {
  #stats {
    display: flex;
    flex-direction: column;
  }
  #stats img {
    width: 300px;
  }
  #stats a {
    margin-bottom: 20px;
    margin-right: -1px;
  }
  #github-top-langs {
    margin-top: -5px;
    margin-right: -5px;
  }
  .ProjectCount {
    color: white;
    padding: 3px;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
    border-radius: 6px;
  }
}

/* --------- */
/* About */
/* --------- */

.about-section {
  position: relative !important;
  padding-top: 150px !important;
  padding-bottom: 30px !important;
  background-image: var(--section-background-color) !important;
  color: white !important;
}

.tech-icons {
  font-size: 4.5em !important;
  margin: 15px !important;
  padding: 10px !important;
  opacity: 0.93 !important;
  border: 2px solid rgba(187, 152, 38, 0.637) !important;
  vertical-align: middle !important;
  text-align: center !important;
  border-radius: 5px !important;
  display: table !important;
  /* box-shadow: 4px 5px 4px 3px rgba(89, 4, 168, 0.137) !important; */
  overflow: hidden !important;
  transition: all 0.4s ease 0s !important;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px,
    rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px,
    rgba(0, 0, 0, 0.09) 0px 32px 16px;
}

.tech-icons:hover {
  transform: scale(1.05) !important;
  overflow: hidden !important;
  border: 2.2px solid rgba(72, 88, 206, 0.883) !important;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px,
    rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px,
    rgba(0, 0, 0, 0.09) 0px 32px 16px;
}
.tech-icons1 {
  font-size: 1.2em !important;
  width: auto !important;
  margin: 15px !important;
  padding: 10px !important;
  opacity: 0.93 !important;
  border: 2px solid rgba(187, 152, 38, 0.637) !important;
  vertical-align: middle !important;
  text-align: center !important;
  border-radius: 5px !important;
  /* display: table !important; */
  /* display: flex !important; */
  /* flex-direction: column !important; */
  /* flex-wrap: wrap !important; */
  /* box-shadow: 4px 5px 4px 3px rgba(89, 4, 168, 0.137) !important; */
  overflow: hidden !important;
  transition: all 0.4s ease 0s !important;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px,
    rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px,
    rgba(0, 0, 0, 0.09) 0px 32px 16px;
}

.tech-icons1:hover {
  transform: scale(1.05) !important;
  overflow: hidden !important;
  border: 2.2px solid rgba(72, 88, 206, 0.883) !important;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px,
    rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px,
    rgba(0, 0, 0, 0.09) 0px 32px 16px;
}

.tech-Conatiner {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

@media (max-width: 767px) {
  .tech-icons {
    margin: 10px !important;
  }
  .tech-icons1 {
    margin: 10px !important;
  }
  .tech-Conatiner {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

.tech-icons:hover {
  transform: scale(1.05) !important;
  overflow: hidden !important;
  border: 2.2px solid rgba(72, 88, 206, 0.883) !important;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px,
    rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px,
    rgba(0, 0, 0, 0.09) 0px 32px 16px;
}
.tech-icon-images {
  padding: 20px !important;
  line-height: 1.6 !important;
}

.quote-card-view {
  border: none !important;
  color: white !important;
  background-color: transparent !important;
}

.about-activity {
  list-style: none !important;
  text-align: left !important;
  padding-left: 1px !important;
}

@media (max-width: 767px) {
  .about-img {
    padding-top: 0 !important;
  }
}
/* --------- */
/* Resume */
/* --------- */

.resume-section {
  position: relative !important;
  padding-top: 110px !important;
  padding-bottom: 30px !important;
  background-image: var(--section-background-color) !important;
  color: white !important;
}

.resume {
  padding-top: 50px;
  padding-bottom: 50px;
  justify-content: center;
}

.resume-left {
  padding-right: 80px !important;
}

.resume-right {
  padding-left: 80px !important;
}
.noteDownload {
  padding-top: 25px;
  font-family: "Courier New", Courier, monospace;
}

@media (max-width: 767px) {
  .resume-left {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }

  .resume-right {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }
  .noteDownload {
    display: none;
  }
}
.resume .resume-title {
  font-size: 2em;
  font-weight: 700;
  padding-top: 30px;
  padding-bottom: 30px;
}

.resume .resume-item {
  padding: 0 0 10px 25px;
  margin-top: -2px;
  border-left: 2px solid #8a49a8;
  position: relative;
}

.resume .resume-item .resume-title {
  line-height: 18px;
  font-size: 0.9em;
  background: #5234795d;
  padding: 8px 15px;
  display: inline-block;
  font-weight: 600;
  margin-bottom: 10px;
}

.resume .resume-item ul {
  padding-left: 20px;
  text-align: justify;
}

.resume .resume-item ul li {
  padding-bottom: 10px;
  list-style: none;
}

.resume .resume-item:last-child {
  padding-bottom: 0;
}

.resume .resume-item::before {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50px;
  left: -9px;
  top: 0;
  background: #fff;
  border: 2px solid #8a49a8;
  /* font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif; */
}

.like-item {
  padding-top: 10px !important;
  font-size: 1.1em !important;
  font-family: sans-serif !important;
}

.like-btn {
  /* background-color: #934cce5e !important; */
  /* border-color: #934cce5e !important; */
  padding: 0.25rem 0.98rem !important;
  border-radius: 5px !important;
  line-height: 1.4 !important;
  transition: 0.3s ease !important;
}

.like-btn:hover {
  transform: translateY(-2px) !important;
  background-color: #a24dd386 !important;
  border-color: #a24dd386 !important;
}

.animate-like {
  animation-name: likeAnimation;
  animation-fill-mode: forwards;
  animation-duration: 0.85s;
}
@keyframes likeAnimation {
  0% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

.fork-btn {
  font-size: 1.1em !important;
  padding-top: 10px !important;
}

.fork-btn-inner {
  line-height: 1.4em !important;
  /* background-color: #934cce5e !important; */
  padding: 0.25rem 1.1rem !important;
  vertical-align: middle !important;
  text-align: center !important;
}

.fork-btn-inner:hover {
  transform: translateY(-2px) !important;
  /* background-color: #a24dd386 !important; */
  /* border-color: #a24dd386 !important; */
}
.fork-btn-inner::after {
  display: none !important;
}

/*    ---------------- Education CSS */
@media (max-width: 767px) {
  .verticalEducation {
    width: 90%;
  }
}

.educationContainer {
  display: flex;
  padding: 4%;
  margin-top: 20px;
}
.link {
  text-decoration: none;
  text-align: left;
  cursor: pointer;
}
.link:hover {
  color: #cc005f;
}
.education {
  min-height: 100vh;
}

/* .education-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: hsla(117, 56%, 49%, 0.702);
} */

.education-image {
  display: flex;

  width: 400px;
}

.education-description {
  width: 100%;
  flex: 0.65;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-left: 7%;
}

.education-description > h1 {
  font-size: 3.5rem;
  font-family: var(--primaryFont);
  margin-bottom: 2rem;
}

.education-card {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 90%;
  height: 140px;
  padding: 1.5rem;
  border-radius: 20px;
  /* margin-bottom: 1.5rem; */
  transition: background-color 200ms ease-in-out;
}

.education-details {
  margin-left: 0.6rem;
}

.education-details > h6 {
  font-family: var(--primaryFont);
  font-size: 0.85rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
  text-align: left;
}

.education-details > h4 {
  font-family: var(--primaryFont);
  font-size: 1.225rem;
  font-weight: 600;
  text-align: left;
}

.education-details > h5 {
  font-family: var(--primaryFont);
  font-size: 1.15rem;
  font-weight: 600;
  text-align: left;
}

.education-details > h5:hover {
  color: #cc005f;
}

.education-image {
  box-sizing: border-box;
  padding: 1rem;
  flex: 0.35;
  margin-top: 1rem;
  pointer-events: none;
  margin-left: -500px;
}

.education-image > img {
  width: 60%;
}

@media (min-width: 992px) and (max-width: 1380px) {
}

@media screen and (max-width: 992px) {
  .education {
    min-height: 100%;
    display: flex;
    flex-direction: column;
  }
  .education-image {
    display: none;
  }

  .education-description {
    flex: 1;
    margin: auto;
  }
  .education-description > h1 {
    font-size: 3.2rem;
  }
  .education-card {
    width: 100%;
    padding: 1.5rem;
    margin-bottom: 1.5rem;
  }
}

@media screen and (max-width: 800px) {
  .education-description > h1 {
    font-size: 3rem;
    margin-bottom: 2rem;
    align-self: center;
  }
  .education-description {
    padding: 1rem;
  }
  .education-card {
    padding: 1rem;
    margin-bottom: 1.5rem;
    height: 130px;
  }
  .educard-img {
    border-radius: 50%;
    width: 45px;
    height: 45px;
  }

  .educard-img > img {
    width: 30px;
  }
  .education-details > h6 {
    font-size: 0.85rem;
    margin-bottom: 0.45rem;
  }

  .education-details > h4 {
    font-size: 1.125rem;
    line-height: 126%;
  }

  .education-details > h5 {
    font-size: 1.05rem;
    line-height: 126%;
  }
}

@media screen and (max-width: 600px) {
  .education-description > h1 {
    font-size: 2.5rem;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 2/3) {
}

@keyframes glitch-1 {
  0% {
    clip: rect(132px, auto, 101px, 30px);
  }

  5% {
    clip: rect(17px, auto, 94px, 30px);
  }

  10% {
    clip: rect(40px, auto, 66px, 30px);
  }

  15% {
    clip: rect(87px, auto, 82px, 30px);
  }

  20% {
    clip: rect(137px, auto, 61px, 30px);
  }

  25% {
    clip: rect(34px, auto, 14px, 30px);
  }

  30% {
    clip: rect(133px, auto, 74px, 30px);
  }

  35% {
    clip: rect(76px, auto, 107px, 30px);
  }

  40% {
    clip: rect(59px, auto, 130px, 30px);
  }

  45% {
    clip: rect(29px, auto, 84px, 30px);
  }

  50% {
    clip: rect(22px, auto, 67px, 30px);
  }

  55% {
    clip: rect(67px, auto, 62px, 30px);
  }

  60% {
    clip: rect(10px, auto, 105px, 30px);
  }

  65% {
    clip: rect(78px, auto, 115px, 30px);
  }

  70% {
    clip: rect(105px, auto, 13px, 30px);
  }

  75% {
    clip: rect(15px, auto, 75px, 30px);
  }

  80% {
    clip: rect(66px, auto, 39px, 30px);
  }

  85% {
    clip: rect(133px, auto, 73px, 30px);
  }

  90% {
    clip: rect(36px, auto, 128px, 30px);
  }

  95% {
    clip: rect(68px, auto, 103px, 30px);
  }

  100% {
    clip: rect(14px, auto, 100px, 30px);
  }
}

@keyframes glitch-2 {
  0% {
    clip: rect(129px, auto, 36px, 30px);
  }

  5% {
    clip: rect(36px, auto, 4px, 30px);
  }

  10% {
    clip: rect(85px, auto, 66px, 30px);
  }

  15% {
    clip: rect(91px, auto, 91px, 30px);
  }

  20% {
    clip: rect(148px, auto, 138px, 30px);
  }

  25% {
    clip: rect(38px, auto, 122px, 30px);
  }

  30% {
    clip: rect(69px, auto, 54px, 30px);
  }

  35% {
    clip: rect(98px, auto, 71px, 30px);
  }

  40% {
    clip: rect(146px, auto, 34px, 30px);
  }

  45% {
    clip: rect(134px, auto, 43px, 30px);
  }

  50% {
    clip: rect(102px, auto, 80px, 30px);
  }

  55% {
    clip: rect(119px, auto, 44px, 30px);
  }

  60% {
    clip: rect(106px, auto, 99px, 30px);
  }

  65% {
    clip: rect(141px, auto, 74px, 30px);
  }

  70% {
    clip: rect(20px, auto, 78px, 30px);
  }

  75% {
    clip: rect(133px, auto, 79px, 30px);
  }

  80% {
    clip: rect(78px, auto, 52px, 30px);
  }

  85% {
    clip: rect(35px, auto, 39px, 30px);
  }

  90% {
    clip: rect(67px, auto, 70px, 30px);
  }

  95% {
    clip: rect(71px, auto, 103px, 30px);
  }

  100% {
    clip: rect(83px, auto, 40px, 30px);
  }
}

/* Contact.css */

.contact-section {
  padding: 6px;
  border: 2px red solid
}

.section-title {
  font-size: 54px;
}

.section-description {
  padding-top: 16px;
  font-size: 40px;
  color: #888;
}

.form-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* align-items: center;fdfefde */
  justify-content: space-around;
  /* gap: 16px; */
}

.email-form {
  display: flex;
  flex-direction: column;
}

.form-label {
  font-weight: bold;
}

/* .form-input,
.form-textarea {
  width: 100%;
  padding: 8px;
  margin-top: 4px;
  border: 1px solid #ccc;
  border-radius: 4px;
} */

input,
textarea {
  color: black;
  width: 100%;
  padding: 12px 20px;
  margin-bottom: 20px;
  border-radius: 10px;
  border: none;
  background-color: #f5f5f5;
}

.submit-btn {
  width: 100%;
  padding: 8px;
  margin-top: 8px;
  background-color: #f4511e;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.image-container {
  margin: 16px;
  width: 200px;
  height: 200px;
}

.contact-image {
  max-width: 100%;
  max-height: 100%;
}

.socials-image {
  display: block;
  margin: auto;
  max-height: 72px;
}


@media (max-width: 767px) {
  .contact-image {
    display:none
  }
}
