@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@500&display=swap");
body {
  margin: 0;
  font-family: "Source Code Pro", monospace !important;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-image: linear-gradient(to left, rgb(27 20 41), rgb(20 15 35)); */
  overflow-x: hidden !important;
  /* overflow-y: hidden; */
  /* background-image: linear-gradient(to left, #BBDEFB, #192f6a); */
  background-image: linear-gradient(to left, #192f6b, #192f6a);
  /* background-image: linear-gradient(to left, #BBDEFB, #BDBDBD); */
  /* background-image: linear-gradient(to left, #B39DDB, #81D4FA); */
  /* background-image: linear-gradient(to left, #FFE082, #81D4FA); */

  /* position: relative; */
  /* z-index: -1; */
  /* background-image: url(./assets/bg2.jpg); */
  /*background-position:  top center; */
  /* background-repeat: no-repeat; */
}
